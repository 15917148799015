import {
  wixCodeItemToProGallery,
  proGalleryItemToWixCode
} from '@wix/pro-gallery-items-formatter'
import { FieldType } from '@wix/wix-data-schema-types'

const { mediaGallery } = FieldType
const convertUploadedFileUrlToMediaGalleryItem = ({
  uploadedFileUrl,
  mediaItemUtils
}) => {
  const mediaItem = mediaItemUtils.parseMediaItemUri(uploadedFileUrl)
  const mediaItemUri = mediaItemUtils.createMediaItemUri(mediaItem).item
  const proGalleryItem = wixCodeItemToProGallery({
    ...mediaItem,
    src: mediaItemUri
  })

  return proGalleryItemToWixCode(proGalleryItem)
}

export default ({
  value: files,
  currentValue = [],
  fieldType,
  mediaItemUtils
}) => {
  return fieldType === mediaGallery
    ? [
        ...currentValue,
        ...files.map(({ fileUrl: uploadedFileUrl }) =>
          convertUploadedFileUrlToMediaGalleryItem({
            uploadedFileUrl,
            mediaItemUtils
          })
        )
      ]
    : files[0].fileUrl
}
